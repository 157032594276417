body{ 
    box-sizing: border-box;
    background-color: #2c5b65;
    min-height: 100vh;
}

*,
*::after,
*::before{
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

.customOverlay {
	background: rgba(36, 123, 160, 0.7);
  }
  .customModal {
	background: #1E1E1E;
	max-width: 500px;
	width: 100%;
	border-color: #d0d0d0;
	border-width: 1px;
	border:#d0d0d0 ;
	border-style:solid;
  }

  .closeButton{
	  color: white;
	  background-color: white;
  }

  .closeIcon{
	color: white;
	background-color: white;
}



/* ========================================================== */


/* 			               01. GENERAL LAYOUT                 */


/* ========================================================== */

p {
	font-size: 17px;
	line-height: 28px;
	font-weight: 400;
	color: #D0D0D0;
	margin-bottom: 15px;
	font-family: 'Electrolize', sans-serif;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
	text-decoration: none;
	outline: 0;
}

a:hover {
	color: #d0d0d0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Electrolize', sans-serif;;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 20px;
	color: #d0d0d0;
	font-weight: 600;
}

h1 {
	font-size: 42px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 26px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 18px;
}


.center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}


.margin-top-80 {
	margin-top: 80px !important;
}

.margin-top-35 {
	margin-top: 35px !important;
}

.margin-top-5 {
	margin-top: 5px !important;
}


.table-row {
	background-color: #000000;
    padding: 5px;
    width: 400px;
    margin: 0 auto;
    max-width: 100%;
}

.table-row .col-6:first-child {
	text-align: right;
}



input.register-input{
    font-family:'Electrolize', sans-serif;
	width: 60%!important;
	height: 48px!important;
	padding-left: 4%!important;
	padding-right: 4%!important;
	margin-bottom: 10px!important;
	color:  #d0d0d0!important;
	border: 1px solid #cecece!important;
	background-color: transparent!important;
	font-size: 16px!important;
	border-radius: 5px 5px 5px 5px!important;
}

input.register-submit {
	background: #d0d0d0;
	border: none;
	color: #1E1E1E;
	letter-spacing: 1px;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	width: 60%;
	max-width: 535px;
	padding: 16px 0;
	text-transform: uppercase;
	-webkit-border-radius: 3px 3px;
	-moz-border-radius: 3px 3px;
	border-radius: 3px 3px;
	transition: all .50s ease-in-out;
	-moz-transition: all .50s ease-in-out;
	-webkit-transition: all .50s ease-in-out;
}

div.responsive-card{
	width: 40rem;
}

@media (max-width: 650px){
	.apple-pic, .google-pic {
		max-width: 80%;
	}
	.logoImg{
		width: 19rem;
	}

	div.responsive-card{
		width: 30rem;
	}

	h6 {
		font-size: 11px;
	}

	p {
		font-size: 11px;
	}
}

@media (max-width: 480px){
	.logoImg{
		width: 19rem;
	}

	div.responsive-card{
		width: 20rem;
	}

	h6 {
		font-size:8px;
	}

	p {
		font-size: 8px;
	}
}

.flex-Show{
	display: flex;
	justify-content: space-around;
}

.black{
	color:#000000
}


.register-input-taxis {
    font-family: 'Electrolize', sans-serif;
    width: 60%!important;
    height: 48px!important;
    padding-left: 4%!important;
    padding-right: 4%!important;
    margin-bottom: 10px!important;
    color: #000000!important;
    border: 1px solid #000000!important;
    background-color: transparent!important;
    font-size: 16px!important;
    border-radius: 5px 5px 5px 5px!important;
}


.register-submit-taxis {
    background: #111111;
    border: none;
    color: #ffffff;
    letter-spacing: 1px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    width: 60%;
    max-width: 535px;
    padding: 16px 0;
    text-transform: uppercase;
    border-radius: 3px 3px;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
}

.taxis-theme h3{

	color:black

}


.taxis-theme h6{

	color:black

}


.taxis-theme span,hr{

	color:white!important

}

.taxis-theme hr{

	border-top: 2px solid rgb(250, 250, 250) !important;

}





